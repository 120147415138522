ul.wonders {
    list-style: none;
    margin: 1rem auto 1rem auto;
    align-self: center;
    align-content: center;
    width: fit-content;

}

li.wonders {
    padding: 1rem 0 0 2rem;
    background-image: url("../icons/tt-wonder.svg");
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: left;
    width: fit-content;
}

ol.wonders {
    margin: 1rem auto 1rem auto;
    align-self: center;
    align-items: center;
    width: fit-content;
}

ol>li{

    border-bottom: 1px black dashed;
}