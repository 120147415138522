ul.cards {
    list-style: none;
    margin: 1rem auto 1rem auto;
    align-self: center;
    align-content: center;
    width: fit-content;

}

li.card {
    padding: .5rem 1rem 0 2rem;
    background-image: url("../icons/19175-blue-house-svg.svg");
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: left;
    width: max-content;
}


li.card:nth-child(2){
    background-image: url("../icons/tt-wells.svg");
}

li.card:nth-child(3){
    background-image: url("../icons/tt-factory.svg");
}

li.card:nth-child(4){
    background-image: url("../icons/tt-churchs.svg");
}

li.card:nth-child(5){
    background-image: url("../icons/tt-tavern.svg");
}

li.card:nth-child(6){
    background-image: url("../icons/tt-shops.svg");
}

li.card:nth-child(7){
    background-image: url("../icons/tt-farms.svg");
}

